import { EmailRegExp, LocalStorageItems } from "../constants/appConstants";
import {
  InspectorReport,
  InspectorReportObject,
  PictureRef,
  TemplateItem,
  TemplateItemType,
  TemplateModel,
  User,
} from "../interfaces/models";
import localForage from "localforage";
import { NIL, v4 as uuidv4 } from "uuid";
import { TemplateState } from "../shared/modules/templates/templateReducer";
import { ReportState } from "../pages/reportReducer";
import EXIF from "exif-js";
import { PdfReport } from "../shared/modules/pdfgen/PdfGenerator";
import { cloneDeep } from "lodash";

const store = localForage.createInstance({
  name: "ServicebookDB",
  version: 1.0,
});

export const combineTemplateTree = (state: TemplateState) => {
  const tree: TemplateModel = {
    id: state.id,
    name: state.name,
    sections: state.sections,
    default: state.default,
    options: state.options,
    created: new Date(),
    modified: new Date(),
    infoItems: state.infoItems,
  };
  if (tree.sections && tree.sections.length) {
    for (let i = 0; i < tree.sections.length; i++) {
      tree.sections[i].items = state.items.filter((x) => x.parentId === tree.sections[i].id);
    }
  }
  tree.options = [...state.options]; //[...state.options.filter((x) => !x.parentId)];
  return tree;
};

export const flattenTemplateTree = (tree: TemplateModel) => {
  const state: TemplateState = {
    id: tree.id,
    name: tree.name || "",
    default: tree.default,
    sections: [],
    items: [],
    options: [],
    loading: false,
    error: undefined,
    created: new Date(),
    modified: new Date(),
    infoItems: [],
  };
  state.sections = [];
  state.items = [];
  state.options = [];

  if (tree.sections)
    tree.sections.forEach((x) => {
      state.sections.push(x);
      state.items = x.items && x.items.length ? [...state.items, ...x.items] : [...state.items];
      x.items = [];
      //delete x.items;
    });

  state.options = tree.options ? [...tree.options] : [];

  return state;
};

export const combineReportTree = (state: ReportState) => {
  const {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    init,
    loading,
    uploadProgress,
    parameters,
    pictures,
    customerReports,
    needSave,
    count,
    maintenanceInfo,
    sync,
    autoSave,
    evaluation,
    collapseAll,
    ...report
  } = state;
  /* eslint-enable @typescript-eslint/no-unused-vars */

  const tree: InspectorReport = cloneDeep(report);

  tree.objects.forEach((x) => {
    x.pictures = state.pictures.filter((z) => z.objectId === x.id && (!z.itemId || z.itemId === NIL));
    x.parameters = state.parameters.filter((y) => y.objectId === x.id);
    x.parameters.forEach((y) => {
      //support the old approach when paramener doesn't have a 'Id' field
      y.pictures = state.pictures.filter(
        (z) => z.itemId === y.id || (z.objectId === y.objectId && z.itemId === y.itemId)
      );
      if (y.value === state.template.options.find((x) => !x.worth)?.value) {
        y.craftsmen = [];
        y.description = "";
        y.priceEstimate = 0;
        y.timeEstimate = 0;
      }
    });
  });
  return tree;
};

export const flattenReportTree = (tree: InspectorReport) => {
  const state: ReportState = {
    ...cloneDeep(tree),
    init: true,
    loading: false,
    uploadProgress: 0,
    parameters: [],
    pictures: [],
    customerReports: [],
    count: 0,
    maintenanceInfo: [],
    needSave: false,
    sync: true,
    autoSave: true,
    wishList: tree.wishList,
    evaluation: 0,
    collapseAll: false,
    loadingPicture: false,
  };

  state.objects.forEach((o) => {
    if (o.pictures?.length) {
      state.pictures = [...state.pictures, ...o.pictures];
      o.pictures = [];
      // delete o.pictures;
    }
    if (o.parameters?.length) {
      state.parameters = [...state.parameters, ...o.parameters];
      o.parameters.forEach((p) => {
        if (p.id === NIL) {
          p.id = uuidv4();
        }
        state.pictures = p.pictures?.length
          ? [
              ...state.pictures,
              ...p.pictures.map((x) => {
                if (x.itemId !== p.id) x.itemId = p.id;
                return x;
              }),
            ]
          : state.pictures;
        if (p.pictures) {
          //delete p.pictures;
        }
      });
      o.parameters = [];
      //delete o.parameters;
    }
  });

  state.evaluation = calculateEvaluation(state.objects);

  return state;
};

export const updateLocalData = async (key: LocalStorageItems, data: any) => {
  if (data) {
    try {
      await store.setItem(key, data, (err, val) => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
          console.log("LS set:", key, err, val);
        }
      });
    } catch (error: unknown) {
      console.warn(error);
    }
  }
};

export const getLocalData = async <T>(key: any) => {
  try {
    return await store.getItem<T>(key, (err, val) => {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        console.log("LS get: ", key, err, val);
      }
    });
  } catch (error: unknown) {
    console.warn(error);
  }
};

export const removeLocalData = async (key: any) => {
  try {
    await store.removeItem(key, (err) => {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        console.log("LS clear:", key, err);
      }
    });
  } catch (error: unknown) {
    console.warn(error);
  }
};

/**
 *
 * @param {{ street, houseNo, floor, doorNumber, postalCode, city }} address myHouse address model, it is used for autocomplete result
 * @param {boolean} short if true returns only street part , otherwise return the address with a city and a postal code
 */
export const formatAddressString = (
  address: {
    street: string;
    houseNo: string;
    floor: string | null;
    doorNumber: string | null;
    postalCode: string | number | null;
    city: string;
  },
  short: boolean
) => {
  let addressString = address.street;
  if (address.houseNo) {
    addressString += " " + address.houseNo;
  }
  if (address.floor) {
    addressString += ", " + address.floor;
  }
  if (address.doorNumber) {
    addressString += ", " + address.doorNumber;
  }
  if (short) return addressString;
  return addressString + ", " + address.postalCode + " " + address.city;
};

export const updateArrayValue = (array: any[] | undefined, payload: { id: string; key: string; value: any }) => {
  if (array) {
    if (!array.filter((x) => x.id === payload.id).length) {
      const item: { id: string; [idx: string]: any } = { id: payload.id };
      item[payload.key] = payload.value;
      return [...array, item];
    }

    const newArray = array.map((x) => {
      if (x.id === payload.id) {
        const updated = { ...x };
        updated[payload.key] = payload.value;
        return updated;
      }
      return x;
    });
    return newArray;
  }
  return [];
};

export const toDataURL = (url: string) =>
  fetch(url, {
    method: "GET",
    mode: "cors",
  })
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

export const getObjectKeyByValue = <T>(obj: T & { [key: string]: any }, value: any) => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

export const getTimestamp = () => {
  const now = new Date();
  return parseInt(now.getHours().toString() + now.getMinutes() + now.getSeconds());
};

export const sleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

export const blobToBase64 = (blob: Blob) => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as unknown as string);
    reader.readAsDataURL(blob);
  });
};

export const makeId = (length: number) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getExifInfo = (imageUrl: string) => {
  return new Promise<number>((resolve) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      EXIF.getData(img as unknown as string, () => {
        const orientation = EXIF.getTag(img, "Orientation");
        resolve(orientation);
      });
    };
  });
};

export const isUuid = (uuid: string) => {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/gi.test(uuid);
};

export const transformReport = async (reportState: ReportState, user: User) => {
  const {
    id,
    created,
    data,
    maintenanceInfo,
    objects,
    parameters,
    pictures,
    template,
    wishList,
    modified,
    evaluation,
    customerLink,
    pdfLink,
    reportType,
    infoItems,
  } = reportState;
  const { firstName, lastName } = user;
  const report: PdfReport = cloneDeep({
    id,
    created,
    data,
    maintenanceInfo,
    objects,
    parameters,
    pictures,
    template,
    wishList,
    modified,
    firstName,
    lastName,
    evaluation,
    customerLink,
    pdfLink,
    reportType,
    infoItems,
  });

  const getOrientationText = (val: number) => {
    switch (val) {
      case 1:
        return "rotate(0deg)";
      case 3:
        return "rotate(180deg)";
      case 6:
        return "rotate(90deg)";
      case 7:
        return "rotate(270deg)";
      case 8:
        return "rotate(270deg)";
    }
  };

  const setPicturesOrientation = async (pics: PictureRef[]) => {
    for (let i = 0; i < pics.length; i++) {
      const pic = pics[i];

      if (pic.url) {
        const orientation = await getExifInfo(pic.url);
        if (!orientation) {
          pic.orientation = getOrientationText(1);
          continue;
        }
        pic.orientation = getOrientationText(orientation);
      }
    }
  };

  await setPicturesOrientation(report.pictures);
  for (let i = 0; i < report.objects.length; i++) {
    await setPicturesOrientation(report.objects[i].pictures);
  }

  for (let i = 0; i < report.parameters.length; i++) {
    await setPicturesOrientation(report.parameters[i].pictures);
    for (let j = 0; j < report.parameters[i].descriptions.length; j++) {
      if (report.parameters[i].descriptions[j].pictures?.length) {
        await setPicturesOrientation(report.parameters[i].descriptions[j].pictures || []);
      }
    }
  }

  return report;
};

export const calculateDegrees = (evaluation: number) => {
  const minEval = 1;
  const maxEval = 6;
  const minDegrees = 0;
  const maxDegrees = 100;

  // Normalize the evaluation to a 0-1 range
  const normalizedEval = (evaluation - minEval) / (maxEval - minEval);

  // Convert the 0-1 range into degrees
  const degrees = normalizedEval * (maxDegrees - minDegrees) + minDegrees;

  return degrees;
};

export const calculateEvaluation = (objects: InspectorReportObject[]) => {
  let avg = 0;
  let total = 0;
  let count = 0;
  objects.forEach((object) => {
    Object.entries(object.sectionEvaluations).forEach((value) => {
      if (value[1] > 0) {
        total += value[1];
        count++;
      }
    });
  });
  if (count > 0) {
    avg = total / count;
  }
  return avg;
};

export const reportApproveValidator = (
  report: ReportState,
  validationByAdmin?: boolean
): { validState: boolean; error: string } => {
  if (!report.data.owner) {
    return { validState: false, error: "Tilføj ejerens navn" };
  }
  const check = EmailRegExp.test(report.data.ownerEmail || "");
  if (!check) {
    return { validState: false, error: "Tilføj ejerens email" };
  }

  if (!validationByAdmin) {
    for (let i = 0; i < report.parameters.length; i++) {
      const object = report.objects?.find((x) => x.id === report.parameters[i].objectId);

      const section = (object?.template || report.template)?.sections?.find((x) =>
        x.items?.some((y) => y.id === report.parameters[i].itemId)
      );

      if (section?.hidden) continue;

      let evaluationUnset = false;

      if (object && section) {
        const value = object.sectionEvaluations?.[section.id];
        evaluationUnset = !value || value < 1;
      }

      if (evaluationUnset) {
        return { validState: false, error: "Tilføj " + section?.name + " tilstand" };
      }

      if (report.parameters[i].hidden) continue;

      const param = section?.items?.find((x) => x.id === report.parameters[i].itemId);
      if (param?.type === TemplateItemType.Group) continue;

      let option = report.template.options.find((x) => x.value === report.parameters[i].value);

      const templateItem = object?.template?.sections
        .reduce<TemplateItem[]>((acc, cur) => acc.concat(cur.items), [])
        .find((x) => x.id === report.parameters[i].itemId);
      if (templateItem?.options) {
        option = templateItem.options.find((x) => x.value === report.parameters[i].value);
      }

      if (option && option.worth && !option.uncorrectable) {
        if (
          !report.parameters[i].craftsmen ||
          (report.parameters[i].craftsmen && !report.parameters[i].craftsmen.length)
        ) {
          return {
            validState: false,
            error: "Tilføj håndværker på " + param?.name + " i " + section?.name + " af " + object?.name,
          };
        }
        if (report.parameters[i].timeEstimate === undefined) {
          return {
            validState: false,
            error: "Tilføj tidsestimering på " + param?.name + " i " + section?.name + " af " + object?.name,
          };
        }
      }
      if (!report.parameters[i].value) {
        return {
          validState: false,
          error: "Tilføj værdi på " + param?.name + " i " + section?.name + " af " + object?.name,
        };
      }
    }
  }
  return { validState: true, error: "" };
};
