import { LoadingButton } from "@mui/lab";
import { Button, Grid, styled, Typography } from "@mui/material";

export const ButtonsContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
    marginBottom: "10px",
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",
  },
  [theme.breakpoints.up("sm")]: {
    maxWidth: "100%",
  },
}));

export const StyledWrapper = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "10px",
  },
}));

export const BottomButton = styled(Button)(({ theme }) => ({
  fontSize: 12,
  marginRight: 0.5,
  minWidth: 140,
  [theme.breakpoints.down("md")]: {
    minWidth: "auto",
  },
}));

export const BottomLoadingButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: 12,
  marginRight: 0.5,
  minWidth: 140,
  [theme.breakpoints.down("md")]: {
    minWidth: "auto",
  },
}));

export const BottomBarMessage = styled(Typography, { shouldForwardProp: (prop) => prop !== "error" })<{
  error: boolean;
}>(({ theme, error }) => ({
  color: error ? "#FF7C39" : "green",
  display: "flex",
  alignItems: "center",
  marginRight: 2,
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    flex: 1,
    marginRight: 3,
  },
}));
