import { Grid, TextField } from "@mui/material";
import { useReportActions, useReportState } from "../reportActions";
import { debounce } from "lodash";

interface ReportAssessmentInfoProps {
  isReadOnly?: boolean;
}

const ReportInfoItems = (props: ReportAssessmentInfoProps) => {
  const report = useReportState();
  const actions = useReportActions();

  const onChangeHandler = (id: string, value: any) => {
    changeValue(id, value);
  };

  const changeValue = debounce((id: string, value: any) => {
    actions.changeInfoItem(id, value);
  }, 100);


  return (
    <Grid item xs={12}>
      {report.infoItems?.length &&
        report.infoItems
          .sort((a, b) => (a.label > b.label ? 1 : -1))
          .map((item) => {
            return (
              <Grid item xs={12} key={item.id}>
                <TextField
                  label={item.title || ""}
                  value={item.description || ""}
                  onChange={(e) => onChangeHandler(item.id, e.target.value)}
                  margin="dense"
                  multiline
                  fullWidth
                  variant="outlined"
                  disabled={props.isReadOnly}
                />
              </Grid>
            );
          })}
    </Grid>
  );
};

export default ReportInfoItems;

{
  /* <Grid item xs={12}>
<TextField
  label={report.infoItems?.na || ""}
  value={report.infoItems?. || ""}
  onChange={(e) => onChangeHandler({ purpose: e.target.value })}
  margin="dense"
  multiline
  fullWidth
  variant="outlined"
  disabled={props.isReadOnly}
/>
</Grid>     */
}
