import React, { memo, useCallback, useEffect, useReducer, useRef } from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  Tooltip,
  Menu,
  MenuItem,
  useMediaQuery,
  IconButton,
  useTheme,
} from "@mui/material";
import { NIL, v4 as uuidv4 } from "uuid";
import NewObjectDialog from "./dialogs/NewObjectDialog";
import Sidebar from "./components/Sidebar";
import EditBuildingsDialog from "./dialogs/EditBuildingsDialog";
import PdfModal, { generatePdf } from "./modals/PdfModal";
import UserInfo from "./components/UserInfo";
import GeneralDialog from "../shared/GeneralDialog";
import NotificationBar, { NotificationType } from "../shared/Notification";
import AppToolbar from "../shared/AppToolbar";
import NewReportDialog from "./dialogs/NewReportDialog";
import {
  AddressFilter,
  FeatureMetadata,
  InspectorReportObject,
  ServiceTargetType,
  UserRole,
} from "../interfaces/models";
import AddressAutocomplete from "../shared/AddressAutocomplete";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { useUser } from "../common/hooks";
import ReportLayout from "./components/ReportLayout";
import { format } from "date-fns";
import daLocale from "date-fns/locale/da";
import { useReport } from "./reportActions";
import { useNavigate, useParams } from "react-router-dom";
import InspectorBottomBar from "./components/InspectorBottomBar";
import { AddressOption } from "../interfaces/frontend";
import { useTemplateActions } from "../shared/modules/templates/templateActions";
import { isUuid, reportApproveValidator } from "../common/utils";
import LoadingOverlay from "../shared/LoadingOverlay";
import { useCommonState } from "../common/commonActions";
import ShareDialog from "./dialogs/ShareDialog";
import { useNetworkState } from "react-use";
import TooltipForDisabled from "../shared/Tooltips";
import ReportDeviceTransferPanel from "./components/ReportDeviceTransferPanel";
import { useUserActions } from "../user/userActions";
import PDFViewer from "./modals/PDFViewer";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import {
  BottomBarMessage,
  BottomButton,
  BottomLoadingButton,
  ButtonsContainer,
  StyledWrapper,
} from "./components/Styled";

type InspectorViewState = {
  newObjectDialogOpened: boolean;
  editDialogOpened: boolean;
  emptyValuesDialogOpened: boolean;
  removeApprovalDialogOpened: boolean;
  confirmApprovalDialogOpened: boolean;
  updateAddressDialogOpened: boolean;
  notificationShown: boolean;
  notificationType: NotificationType;
  notificationText: string;
  tabIndexValue: number;
  openDrawer: boolean;
  autocompleteValue: string;
  menuAnchor: HTMLButtonElement | null;
  houseData: FeatureMetadata | null;
  targetType: ServiceTargetType;
  error: string;
  openNewDialog: boolean;
  openSave: boolean;
  showPdfDialog: boolean;
  lastSavedTime?: Date;
  showSaveWarning: boolean;
  showReportInterface: boolean;
  transferReportDialogOpened: boolean;
  manualDialogOpened: boolean;
  employeeToTransfer: string;
  loading: boolean;
  showShareDialog: boolean;
  pdfManualBlob: Blob | null;
};

const initialState: InspectorViewState = {
  newObjectDialogOpened: false,
  showPdfDialog: false,
  editDialogOpened: false,
  emptyValuesDialogOpened: false,
  removeApprovalDialogOpened: false,
  confirmApprovalDialogOpened: false,
  updateAddressDialogOpened: false,
  notificationShown: false,
  notificationType: "success",
  notificationText: "",
  tabIndexValue: 0,
  openDrawer: false,
  autocompleteValue: "",
  menuAnchor: null,
  houseData: null,
  targetType: ServiceTargetType.Unit,
  error: "",
  openNewDialog: false,
  openSave: false,
  lastSavedTime: undefined,
  showSaveWarning: false,
  showReportInterface: false,
  transferReportDialogOpened: false,
  manualDialogOpened: false,
  employeeToTransfer: NIL,
  loading: false,
  showShareDialog: false,
  pdfManualBlob: null,
};

const reducer = (
  state: InspectorViewState,
  action: { type: keyof InspectorViewState | "showNotification"; payload: any }
) => {
  if (action.type === "showNotification") {
    return { ...state, ...action.payload };
  }
  return { ...state, [action.type]: action.payload };
};

const sectionRefs: { [key: string]: { [key: string]: HTMLDivElement | null } } = {};

//Works with report `needSave` trigger
export const ChangeContext = React.createContext<React.MutableRefObject<number>>(undefined!);

export type ViewerProps = {
  addressFilter: AddressFilter;
  noHistory: boolean;
  newReportDialog: boolean;
};

const Viewer = (props: ViewerProps) => {
  //const classes = useStyles();
  const user = useUser();
  const navigate = useNavigate();

  const { id } = useParams();

  const templateActions = useTemplateActions();

  const [report, actions] = useReport();
  const common = useCommonState();

  const [state, dispatch] = useReducer(reducer, initialState);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const timerRef = useRef<NodeJS.Timeout>();
  const changeRef = useRef(0);

  const pdfFile = useRef<Blob | null>(null);

  const stateNet = useNetworkState();

  const userActions = useUserActions();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (report.needSave && !report.approved) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [report.needSave, report.approved]);

  const uploadReport = useCallback(async () => {
    const needNavigate = report.id === NIL;
    try {
      const id = await actions.uploadReport();

      changeRef.current = 0;
      const localReport = await actions.getReport(id);

      dispatch({
        type: "showNotification",
        payload: {
          notificationShown: true,
          notificationText: localReport?.isMerged
            ? "Rapport gemt. Data fra lokal lagring blev tilføjet."
            : "Rapporten gemt",
          notificationType: "success",
          showSaveWarning: false,
        },
      });
      if (needNavigate) {
        navigate(`/inspector/report/edit/${id}`, { replace: true });
        await actions.getReportList("", 0, 10, props.noHistory, AddressFilter.WithAddress, false, true);
        return;
      }
      await actions.getReportList("", 0, 10, props.noHistory, props.addressFilter, false, true);
    } catch (error) {
      console.error(error);
    }
  }, [actions, navigate, props.addressFilter, props.noHistory, report.id]);

  const openNewReportDialog = useCallback(() => {
    templateActions.getTemplateList().then(
      () => {
        dispatch({ type: "openNewDialog", payload: true });
      },
      (error) => {
        dispatch({
          type: "showNotification",
          payload: {
            notificationShown: true,
            notificationText: error,
            notificationType: "error",
          },
        });
      }
    );
  }, [templateActions]);

  const getLocalReport = useCallback(async () => {
    actions.getLocalReport().then((report) => {
      switch (props.addressFilter) {
        case AddressFilter.NoAddress:
          if (!report || report.data.address) {
            if (props.newReportDialog) {
              openNewReportDialog();
            }
          } else {
            dispatch({ type: "showReportInterface", payload: true });
          }
          break;

        case AddressFilter.WithAddress:
          if (report && report.data.address && props.addressFilter === AddressFilter.WithAddress) {
            dispatch({ type: "showReportInterface", payload: true });
          }
          break;
      }
    });
  }, [actions, openNewReportDialog, props.addressFilter, props.newReportDialog]);

  const getReports = useCallback(async () => {
    actions.getReportList("", 0, 10, props.noHistory, props.addressFilter, false, true).then(() => {
      if (props.addressFilter === AddressFilter.WithAddress && !id && !report.data.address) {
        dispatch({ type: "openDrawer", payload: true });
      }
    });
  }, [actions, id, props.addressFilter, props.noHistory, report.data.address]);

  //here are the startup functions in the correct order
  const setUp = useCallback(async () => {
    await getLocalReport();
    await getReports();
  }, [getLocalReport, getReports]);

  useEffect(() => {
    setUp();
    const clearAsync = async () => {
      await actions.clearReport(undefined, true);
    };
    return () => {
      //TODO: think if to clean when exiting or when going somewhere else. If user goes back immediately - no need to reload local report?
      //actions.clearReport();
      dispatch({ type: "tabIndexValue", payload: 0 });
      //save local storage report data to prevent losing data after page will be refresh
      clearAsync();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkUploadReport = useCallback(async () => {
    if (report.autoSave) {
      await uploadReport();
    }
  }, [report.autoSave, uploadReport]);

  useEffect(() => {
    if (!report.autoSave || report.approved || (report.id === NIL && !report.data.address)) return;

    if (report.needSave) {
      dispatch({ type: "showSaveWarning", payload: true });
      if (changeRef.current > 0) {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(checkUploadReport, 60 * 500);
      }
    } else {
      clearTimeout(timerRef.current);
    }
    return () => {
      clearTimeout(timerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.needSave, report.id, common.pdfView, report.approved]);

  const getAsync = useCallback(async () => {
    if (id && isUuid(id) && report.id !== id) {
      actions.getReport(id).then(() => dispatch({ type: "showReportInterface", payload: true }));
    }
  }, [actions, id, report.id]);

  useEffect(() => {
    getAsync();
    return () => {
      //
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const editDialogClose = useCallback(
    async (values?: InspectorReportObject[]) => {
      dispatch({ type: "editDialogOpened", payload: false });
      if (values?.length) {
        dispatch({ type: "tabIndexValue", payload: values.length - 1 });
        await actions.updateObjects(values);
      }
    },
    [actions]
  );

  const showPdf = useCallback(async () => {
    dispatch({ type: "showPdfDialog", payload: false });

    try {
      actions.updateMaintenanceInfo();
    } finally {
      if (report.autoSave) {
        actions.toggleAutoSave();
      }
      await uploadReport();
      await actions.openPdfView();
    }
  }, [actions, report.autoSave, uploadReport]);

  const openNewObjectDialog = useCallback(() => {
    dispatch({ type: "newObjectDialogOpened", payload: true });
  }, []);
  // const openPdfDialog = () => {
  //   dispatch({ type: "showPdfDialog", payload: true });
  // };

  const closePdfDialog = useCallback(
    (accepted?: boolean) => {
      dispatch({ type: "showPdfDialog", payload: false });
      if (accepted) {
        showPdf();
      }
    },
    [showPdf]
  );

  const openEditDialog = () => {
    dispatch({ type: "editDialogOpened", payload: true });
  };

  const addDialogClose = async (value?: string) => {
    dispatch({ type: "newObjectDialogOpened", payload: false });
    if (value) {
      await actions.addReportObject({
        id: uuidv4(),
        name: value,
        label: "",
        description: "",
        pictures: [],
        parameters: [],
        template: report.template,
        sectionEvaluations: {},
      });
    }
  };

  const handleDrawerToggle = () => {
    dispatch({ type: "openDrawer", payload: !state.openDrawer });
  };

  const getReport = async (id: string) => {
    dispatch({ type: "openDrawer", payload: false });
    dispatch({ type: "tabIndexValue", payload: 0 });
    if (props.addressFilter === AddressFilter.WithAddress) {
      navigate(`/inspector/report/edit/${id}`, { replace: true });
    } else {
      await actions.getReport(id);
    }
  };

  const emptyValuesDialogOpen = () => {
    dispatch({ type: "emptyValuesDialogOpened", payload: true });
  };

  const emptyValuesDialogClose = async (value?: boolean) => {
    dispatch({ type: "emptyValuesDialogOpened", payload: false });
    if (value) {
      await actions.updateEmptyValues();
    }
  };

  const openUnapproveDialog = () => {
    dispatch({ type: "removeApprovalDialogOpened", payload: true });
  };

  const openApproveDialog = () => {
    dispatch({ type: "confirmApprovalDialogOpened", payload: true });
  };

  const removeReportApproval = async (value?: boolean) => {
    dispatch({ type: "removeApprovalDialogOpened", payload: false });
    if (value) {
      await actions.unapproveReport();
    }
  };

  const confirmReportApproval = async (value?: boolean) => {
    dispatch({ type: "confirmApprovalDialogOpened", payload: false });
    if (value) {
      await approveReport();
    }
  };

  const errorClose = () => {
    dispatch({ type: "error", payload: "" });
  };

  const approveReport = async () => {
    try {
      const reportValidation = reportApproveValidator(report);

      if (!reportValidation.validState) {
        dispatch({ type: "error", payload: reportValidation.error });
        return;
      }

      dispatch({ type: "loading", payload: true });
      await uploadPdf();
      await actions.approveReport();

      dispatch({
        type: "showNotification",
        payload: {
          notificationShown: true,
          notificationText: "Rapporten er godkendt",
          notificationType: "success",
        },
      });
    } finally {
      dispatch({ type: "loading", payload: false });
    }
  };

  const handleNotificationClose = () => {
    dispatch({ type: "notificationShown", payload: false });
  };

  const goToMenuClick = (event: { currentTarget: any }) => {
    dispatch({ type: "menuAnchor", payload: event.currentTarget });
  };

  const closeMenu = () => {
    dispatch({ type: "menuAnchor", payload: null });
  };

  const handleGoToMenuClick = (id: string) => {
    dispatch({ type: "menuAnchor", payload: null });
    if (report.objects?.length) {
      sectionRefs[id][report.objects[state.tabIndexValue].id]?.scrollIntoView();
      window.scrollBy(0, -60);
    }
  };

  const openSaveDialog = () => {
    if (report.data && !report.data.address) {
      dispatch({ type: "openSave", payload: true });
    } else {
      uploadReport();
    }
  };

  const closeSaveDialog = (forceSave?: boolean) => {
    dispatch({ type: "openSave", payload: false });
    if (forceSave) {
      uploadReport();
      dispatch({ type: "lastSavedTime", payload: new Date() });
    }
  };

  const closePdf = () => {
    if (!report.autoSave) {
      actions.toggleAutoSave();
    }
    actions.closePdfView();
  };

  const changeAutocompleteValue = async (value: AddressOption) => {
    if (value.value) {
      dispatch({ type: "autocompleteValue", payload: false });
      dispatch({ type: "houseData", payload: value.houseData });
      dispatch({ type: "targetType", payload: value.targetType });
      if (report.id !== NIL || report.parameters.length) {
        dispatch({ type: "updateAddressDialogOpened", payload: true });
      } else {
        changeRef.current++;
        await actions.setReportData(value.houseData as FeatureMetadata, value.targetType);
      }
    }
  };

  const updateReportData = async (confirmed: boolean | undefined) => {
    dispatch({ type: "updateAddressDialogOpened", payload: false });
    if (confirmed) {
      changeRef.current++;
      dispatch({ type: "loading", payload: true });
      try {
        await actions.setReportData(state.houseData, state.targetType, true);
        await uploadReport();
      } catch (error) {
        console.error(error);
      } finally {
        dispatch({ type: "loading", payload: false });
      }
    }
  };

  const clearReport = async (dialogResult: { accepted: boolean; templateId: string }) => {
    dispatch({ type: "openNewDialog", payload: false });
    dispatch({ type: "showSaveWarning", payload: false });
    dispatch({ type: "tabIndexValue", payload: 0 });

    if (dialogResult && dialogResult.accepted) {
      try {
        const template = common.templateList.find((x) => x.id === dialogResult.templateId);
        await actions.clearReport(template);
        dispatch({ type: "showReportInterface", payload: true });
      } catch (error) {
        dispatch({
          type: "showNotification",
          payload: {
            notificationShown: true,
            notificationText: error,
            notificationType: "error",
          },
        });
      }
    } else {
      navigate(-1);
    }
  };

  const applyFilter = (filter: string) => {
    actions.getReportList(filter, 0, 10, props.noHistory, props.addressFilter, false, true);
  };

  const changeEmployee = useCallback((employeeId: string) => {
    dispatch({ type: "employeeToTransfer", payload: employeeId });
    dispatch({ type: "transferReportDialogOpened", payload: true });
  }, []);

  const transferReport = useCallback(
    async (confirmed?: boolean) => {
      dispatch({ type: "transferReportDialogOpened", payload: false });
      if (confirmed && state.employeeToTransfer !== NIL) {
        try {
          await actions.transferReport(state.employeeToTransfer);
          clearTimeout(timerRef.current);
          dispatch({
            type: "showNotification",
            payload: {
              notificationShown: true,
              notificationText: "Report transferred",
              notificationType: "success",
            },
          });
          await actions.clearReport(report.template);
          changeRef.current = 0;
          navigate("/inspector/report/create", { replace: true });
          actions.getReportList("", 0, 10, props.noHistory, AddressFilter.WithAddress, false, true);
        } catch (error) {
          dispatch({
            type: "showNotification",
            payload: {
              notificationShown: true,
              notificationText: "Report not transferred",
              notificationType: "error",
            },
          });
        }
      }
    },
    [actions, navigate, props.noHistory, report.template, state.employeeToTransfer]
  );

  const collapseAllSections = () => {
    actions.setCollapseAll(true);
  };

  const uploadPdf = useCallback(async () => {
    try {
      const pdf = await generatePdf(report, user);
      if (!pdf) {
        dispatch({
          type: "showNotification",
          payload: {
            notificationShown: true,
            notificationText: "Failed to generate PDF.",
            notificationType: "error",
          },
        });
        return;
      }

      await actions.saveDoc(report.id, pdf);
    } catch (error) {
      dispatch({
        type: "showNotification",
        payload: {
          notificationShown: true,
          notificationText: "Failed to upload PDF.",
          notificationType: "error",
        },
      });
      return;
    }
  }, [actions, report, user]);

  const openShareDialog = useCallback(async () => {
    try {
      dispatch({ type: "loading", payload: true });
      if (!report.approved) {
        dispatch({
          type: "showNotification",
          payload: {
            notificationShown: true,
            notificationText: "Report is not approved yet. You can't share it.",
            notificationType: "error",
          },
        });
        return;
      }
      if (!report.pdfLink) {
        await uploadPdf();
      }

      const response = await fetch(report.pdfLink);
      if (!response.ok) {
        dispatch({
          type: "showNotification",
          payload: {
            notificationShown: true,
            notificationText: "PDF failed to generate.",
            notificationType: "error",
          },
        });
        return;
      }
      const blob = await response.blob();
      pdfFile.current = blob;

      dispatch({
        type: "showShareDialog",
        payload: true,
      });
    } finally {
      dispatch({ type: "loading", payload: false });
    }
  }, [report.approved, report.pdfLink, uploadPdf]);

  const handleShare = useCallback(() => {
    dispatch({ type: "showShareDialog", payload: false });

    if (pdfFile.current) {
      const shareData: ShareData = {
        files: [
          new File([pdfFile.current], report.data.address + ", " + report.data.city + ".pdf", {
            type: "application/pdf",
          }),
        ],
      };
      if (navigator.canShare(shareData)) {
        navigator.share(shareData).catch(() => {
          dispatch({
            type: "showNotification",
            payload: {
              notificationShown: true,
              notificationText: "Failed to share PDF. Try to download it instead.",
              notificationType: "error",
            },
          });
        });
      }
    }
  }, [report.data.address, report.data.city]);

  const handleDownload = useCallback(() => {
    dispatch({ type: "showShareDialog", payload: false });
    if (pdfFile.current) {
      const url = window.URL.createObjectURL(pdfFile.current);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", report.data.address + ", " + report.data.city + ".pdf"); // or any other filename you want
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [report.data.address, report.data.city]);

  const openManualDialog = useCallback(async () => {
    const fileBlob = await userActions.getManual("da");
    if (fileBlob && fileBlob.size > 0) {
      await dispatch({ type: "pdfManualBlob", payload: fileBlob });
      await dispatch({ type: "manualDialogOpened", payload: true });
    }
  }, [userActions]);

  const handleCloseManualViewer = useCallback(() => {
    dispatch({ type: "manualDialogOpened", payload: false });
    dispatch({ type: "pdfManualBlob", payload: initialState.pdfManualBlob });
  }, []);

  return (
    <>
      <AppToolbar
        handleDrawerToggle={handleDrawerToggle}
        loading={report.loading || common.loading}
        buttons={
          <>
            {!user.isAdmin && props.newReportDialog && (
              <>
                <Tooltip arrow title="Ny rapport">
                  <IconButton color="inherit" onClick={openNewReportDialog} size="large">
                    <PostAddIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}

            {report.id !== NIL && (
              <>
                {report.approved ? (
                  <Tooltip arrow title="PDF">
                    <Button variant="outlined" color="inherit" onClick={openShareDialog}>
                      <Typography variant="body1">PDF</Typography>
                    </Button>
                  </Tooltip>
                ) : (
                  <>
                    <Tooltip arrow title="Godkend">
                      <Button variant="outlined" color="inherit" onClick={openApproveDialog}>
                        <Typography variant="body1">Godkend</Typography>
                      </Button>
                    </Tooltip>
                    {/* <Tooltip arrow title="Vis PDF">
                      <Button color="inherit" onClick={openPdfDialog}>
                        <Typography variant="body1">PDF</Typography>
                      </Button>
                    </Tooltip> */}
                  </>
                )}
              </>
            )}

            <Tooltip arrow title="Serviceeftersyns manual">
              <QuestionMarkIcon
                sx={{
                  marginRight: "0",
                  marginLeft: "15px",
                  width: "40px",
                  minWidth: "40px",
                }}
                color="inherit"
                onClick={() => {
                  openManualDialog();
                }}
              />
            </Tooltip>
          </>
        }
      ></AppToolbar>

      <Sidebar
        open={state.openDrawer}
        handleDrawerToggle={handleDrawerToggle}
        title="Seneste ikke godkendt rapporter"
        items={report.reports}
        emptyName="Tom adresse"
        staticData={{
          id: report.id,
          title: "Aktiv",
          name:
            report.data && report.data.address
              ? `${report.data.address}, ${report.data.postcode} ${report.data.city}`
              : "Tom adresse",
          modified: report.modified ? "Gemt " + new Date(report.modified).toLocaleString() : "",
        }}
        onItemClick={getReport}
        applyFilter={applyFilter}
        className={""}
      />
      {state.showReportInterface && (
        <Container sx={{ py: 10 }}>
          <ReportDeviceTransferPanel />
          {!user.isAdmin && (
            <>
              <Grid container justifyContent="center" alignItems="center">
                <UserInfo
                  userId={user.id}
                  employees={user.company.employees}
                  showChange={report.id !== NIL && report.allowedToEdit && !report.approved} //????
                  changeEmployee={changeEmployee}
                />
              </Grid>
              {!report.approved && !report.data.address && (
                <Grid container justifyContent="center" alignItems="center">
                  <AddressAutocomplete selectedAction={changeAutocompleteValue} />
                </Grid>
              )}
            </>
          )}
          <ChangeContext.Provider value={changeRef}>
            {report.data.address && (
              <ReportLayout
                sectionRefs={sectionRefs}
                openEditDialog={openEditDialog}
                openNewObjectDialog={openNewObjectDialog}
                changedTabIndexCallback={(index: number) => dispatch({ type: "tabIndexValue", payload: index })}
                showTitle={false}
                isReadOnly={report.approved || !report.allowedToEdit}
              />
            )}
          </ChangeContext.Provider>
        </Container>
      )}
      <InspectorBottomBar>
        {state.showReportInterface && (
          <StyledWrapper container justifyContent="space-between" alignItems="center">
            <ButtonsContainer item xs={9} sm={6}>
              <BottomButton style={{ marginLeft: 5 }} variant="contained" color="primary" onClick={goToMenuClick}>
                Gå til
              </BottomButton>
              <BottomButton
                variant="contained"
                color="primary"
                onClick={emptyValuesDialogOpen}
                disabled={report.approved}
              >
                Opdater med IB
              </BottomButton>
              <BottomButton
                variant="contained"
                color="primary"
                onClick={collapseAllSections}
                disabled={report.approved}
              >
                Luk alle
              </BottomButton>

              {user.isAdmin ||
                (user.role === UserRole.CompanyManager && (
                  <BottomButton
                    variant="outlined"
                    onClick={openUnapproveDialog}
                    disabled={!(report.approved && report.id)}
                  >
                    UNAPPROVE
                  </BottomButton>
                ))}
            </ButtonsContainer>
            <Grid item xs={9} sm={6} container justifyContent="flex-end" alignItems="center">
              <>
                {stateNet && !stateNet.online && (
                  <BottomBarMessage variant={"body2"} error>
                    UNCONNECTED
                  </BottomBarMessage>
                )}

                <BottomBarMessage variant={"body2"} error={report.needSave || report.id === NIL}>
                  {!report.approved &&
                    (report.needSave || report.id === NIL
                      ? isXs
                        ? "IKKE GEMT"
                        : "RAPPORTEN ER IKKE GEMT"
                      : isXs
                      ? "Gemt " +
                        format(new Date(report.modified), "dd.MM.yy HH:mm", {
                          locale: daLocale,
                        })
                      : "Rapporten gemt " +
                        format(new Date(report.modified), "dd MMM yyyy 'kl.' HH:mm", {
                          locale: daLocale,
                        }))}
                </BottomBarMessage>
                <TooltipForDisabled title="Godkendt rapport kan ikke ændres">
                  <BottomLoadingButton
                    size={isXs ? "small" : "medium"}
                    variant="outlined"
                    color="secondary"
                    onClick={openSaveDialog}
                    loading={report.loading || report.loadingPicture}
                    disabled={!report.needSave || report.approved || !report.allowedToEdit}
                  >
                    Gem
                  </BottomLoadingButton>
                </TooltipForDisabled>
              </>
            </Grid>
          </StyledWrapper>
        )}
      </InspectorBottomBar>

      <NewObjectDialog open={state.newObjectDialogOpened} handleClose={addDialogClose} />

      {state.showPdfDialog && (
        <GeneralDialog
          open={state.showPdfDialog}
          okButtonText="OK"
          dialogTitle="Åbn rapporten som PDF"
          dialogText="Når du åbner rapporten som PDF, har du mulighed for at se den igennem inden godkendelse. Når PDFén er åben kan du gå tilbage eller godkende rapporten. Godkender du rapporten, sendes denne til kunden pr mail."
          handleClose={closePdfDialog}
        />
      )}
      {state.editDialogOpened && (
        <EditBuildingsDialog
          open={state.editDialogOpened}
          handleClose={editDialogClose}
          objects={report.objects.map((x) => {
            return {
              id: x.id,
              name: x.name,
              label: x.label,
              description: x.description,
              pictures: x.pictures,
              parameters: x.parameters,
              template: undefined,
              sectionEvaluations: x.sectionEvaluations,
            };
          })}
        />
      )}
      {common.pdfView && (
        <PdfModal
          shown={common.pdfView}
          needApprove={!report.approved}
          handleClose={closePdf}
          handleApprove={approveReport}
        />
      )}
      <GeneralDialog
        open={state.emptyValuesDialogOpened}
        dialogTitle={"Ønsker du at udfylde alle tomme skadeskategorier med IB?"}
        handleClose={emptyValuesDialogClose}
      />
      <GeneralDialog
        open={state.removeApprovalDialogOpened}
        dialogTitle={"Fjern godkendelse"}
        dialogText={"Ønsker du at fjerne godkendelsen af den aktuelle rapport?"}
        handleClose={removeReportApproval}
      />
      <GeneralDialog
        open={state.confirmApprovalDialogOpened}
        dialogTitle={"Godkend rapport"}
        dialogText={"Denne rapport vil blive sendt til kunden efter godkendelse og kan dernæst ikke længere redigeres"}
        handleClose={confirmReportApproval}
      />
      <GeneralDialog
        open={!!state.error}
        dialogTitle={"Fejl"}
        dialogText={state.error}
        handleClose={errorClose}
        hideCancel
        okButtonText="Ok"
      />
      <GeneralDialog
        open={state.updateAddressDialogOpened}
        dialogTitle={"Indstille adresse"}
        dialogText={"Ønsker du at indstille den adresse til rapporten?"}
        handleClose={updateReportData}
        okButtonText="Indstille"
        cancelButtonText="Nej"
      />
      <GeneralDialog
        open={state.openSave}
        dialogTitle={"Gem rapporten"}
        dialogText={"Ønsker du at gemme rapporten med en tom adresse?"}
        handleClose={closeSaveDialog}
      />
      <GeneralDialog
        open={state.transferReportDialogOpened}
        dialogTitle={"Ændre sagkyndig"}
        dialogText={"Vil du ændre sagkyndig, på denne opgave?"}
        handleClose={transferReport}
      />
      <NotificationBar
        open={state.notificationShown}
        variant={state.notificationType}
        handleClose={handleNotificationClose}
        message={state.notificationText}
      />
      <LoadingOverlay loading={state.loading} backgroundColor={"rgba(255,255,255, 0.5)"} />
      <Menu id="goto-menu" anchorEl={state.menuAnchor} keepMounted open={!!state.menuAnchor} onClose={closeMenu}>
        <MenuItem
          key="top-menu-item"
          onClick={() => {
            window.scrollTo(0, 0);
            closeMenu();
          }}
        >
          Top
        </MenuItem>
        {report.template &&
          report.template.sections &&
          report.template.sections.map((x) => (
            <MenuItem key={x.id} onClick={() => handleGoToMenuClick(x.id)}>
              {`${x.label}. ${x.name}`}
            </MenuItem>
          ))}
      </Menu>
      <NewReportDialog open={state.openNewDialog} handleClose={clearReport} />
      <ShareDialog
        open={state.showShareDialog}
        handleClose={() => dispatch({ type: "showShareDialog", payload: false })}
        handleShare={handleShare}
        handleDownload={handleDownload}
      />
      <PDFViewer
        pdfBlob={state.pdfManualBlob}
        open={state.manualDialogOpened}
        handleClose={handleCloseManualViewer}
      ></PDFViewer>
    </>
  );
};

export default Viewer;
