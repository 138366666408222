import { Report, Common, User, Admin, AppAction } from "../constants/actionTypes";
import { NIL, v4 as uuidv4 } from "uuid";
import { updateArrayValue } from "../common/utils";
import {
  InfoItem,
  InspectorReport,
  InspectorReportParameter,
  PictureRef,
  ServiceReportType,
  TemplateModel,
  WishListParameter,
} from "../interfaces/models";
import { cloneDeep } from "lodash";
import { MaintenanceInfo } from "../interfaces/frontend";

export interface ReportState extends InspectorReport {
  init: boolean;
  loading: boolean;
  approved: boolean;
  uploadProgress: number;
  parameters: InspectorReportParameter[];
  pictures: PictureRef[];
  customerReports: InspectorReport[];
  needSave: boolean;
  count: number;
  maintenanceInfo: MaintenanceInfo[];
  sync: boolean;
  autoSave: boolean;
  evaluation: number;
  collapseAll: boolean;
  newCustomParamIds?: string[];
  isMerged?: boolean;
  loadingPicture: boolean;
  reportType: ServiceReportType;
  isActive?: boolean;
  infoItems: InfoItem[];
}

export const initReportState = (template?: TemplateModel) => {
  const report = cloneDeep(initialState);
  if (!template) return report;

  report.template = template;

  report.objects?.push({
    id: uuidv4(),
    label: "A",
    name: "Primær bygning",
    description: "",
    pictures: [],
    parameters: [],
    template: template,
    sectionEvaluations: {},
  });

  template.sections?.forEach((section) => {
    section.items?.forEach((item) => {
      report.parameters.push({
        id: uuidv4(),
        itemId: item.id,
        objectId: report.objects[0].id,
        timeEstimate: 0,
        priceEstimate: 0,
        hidden: false,
        craftsmen: [],
        label: item.label,
        name: item.name,
        note: "",
        pictures: [],
        value: "",
        descriptions: [],
        groupItemId: item.groupId,
      });
    });
  });

  report.infoItems = template.infoItems;

  return report;
};

const initialState: ReportState = {
  id: NIL,
  data: {
    postcode: 0,
    address: "",
    city: "",
    cadastral: "",
    caseNumber: 0,
    reportDate: new Date(),
    houseId: NIL,
    municipality: "",
    owner: "",
    latitude: 0,
    longitude: 0,
    picture: "",
    ownerLastName: "",
    area: 0,
    basementArea: 0,
    buildYear: 0,
    groundArea: 0,
    livingArea: 0,
    rooms: 0,
    unitArea: 0,
    energyLabel: "",
    ownerEmail: "",
    ownerPhone: "",
    propertyType: "",
    unitType: "",
  },
  //initially there's no template but could be a bug
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  template: undefined!,
  created: new Date(),
  modified: new Date(),
  parameters: [],
  pictures: [],
  objects: [],
  init: true,
  loading: false,
  approved: false,
  uploadProgress: 0,
  customerReports: [],
  needSave: false,
  count: 0,
  maintenanceInfo: [],
  sync: true,
  autoSave: true,
  wishList: [],
  evaluation: 0,
  collapseAll: false,
  customerLink: "",
  pdfLink: "",
  isMerged: false,
  loadingPicture: false,
  createdBy: "",
  allowedToEdit: true,
  reportType: ServiceReportType.BasicReport,
  infoItems: []
};

const reportReducer = (state = initialState, action: AppAction) => {
  switch (action.type) {
    // case Common.GET_LIST:
    //   return { ...state, loading: true };
    case Report.GET_CUSTOMER_REPORT_LIST_SUCCEEDED:
      return {
        ...state,
        loading: false,
        customerReports: action.payload.results,
        count: action.payload.count,
      };
    case Report.GET_REPORT_TEMPLATE:
      return { ...state, loading: true };
    case Common.GET_REPORT_LIST_SUCCEEDED:
    case Common.GET_TEMPLATE_LIST_SUCCEEDED:
      return { ...state, loading: false, init: false };
    case Report.GET_REPORT_TEMPLATE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        template: action.payload,
        init: false,
      };
    case Report.GET_REPORT:
      return {
        ...state,
        loading: true,
      };
    case Report.GET_REPORT_SUCCEEDED:
      return {
        ...initialState,
        ...action.payload,
        loading: false,
        init: false,
        needSave: false,
      };
    case Report.GET_REPORT_TEMPLATE_FAILED:
    case Report.GET_REPORT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        needSave: false,
      };
    case Report.CHANGE_REPORT_VALUE: {
      let needSave = false;
      const newParams = state.parameters.map((x) => {
        if (x.id === action.payload.itemId) {
          const updated: InspectorReportParameter = { ...x };
          updated[action.payload.key] = action.payload.value;
          needSave = true;
          return updated;
        }
        return x;
      });
      return {
        ...state,
        parameters: newParams,
        needSave: needSave,
        newCustomParamIds: state.newCustomParamIds?.length
          ? state.newCustomParamIds.filter((x) => x !== action.payload.itemId)
          : [],
      };
    }
    case Report.UPDATE_REPORT_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.key]:
            action.payload.key == "ownerPhone" ? (action.payload.value || "").toString() : action.payload.value,
        },
        needSave: true,
      };
    case Report.SET_REPORT_DATA: {
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        needSave: true,
      };
    }
    case Report.LOCAL_TEMPLATE_NOT_FOUND:
      return { ...state, init: false, needSave: false };
    case Report.ADD_REPORT_OBJECT:
      return {
        ...state,
        objects: state.objects
          ? [
              ...state.objects,
              //{ id: action.payload.id, name: action.payload.name }
              action.payload,
            ]
          : [action.payload],
        needSave: true,
      };
    case Report.CHANGE_REPORT_OBJECT:
      return {
        ...state,
        objects: updateArrayValue(state.objects, action.payload),
        needSave: true,
      };
    case Report.UPDATE_REPORT_OBJECTS: {
      return { ...state, objects: action.payload, needSave: true };
    }
    case Report.APPROVE_REPORT_SUCCEEDED:
      return { ...state, approved: true, error: null, loading: false };
    case Report.APPROVE_REPORT_FAILED:
    case Admin.UNAPPROVE_REPORT_FAILED:
      return { ...state, loading: false, error: action.payload };
    case Admin.UNAPPROVE_REPORT_SUCCEEDED:
      return { ...state, approved: false, error: null };
    case Report.UPDATE_REPORT_EMPTY_VALUES:
    case Report.UPDATE_REPORT_PARAMETERS:
      return { ...state, parameters: action.payload, needSave: true };
    case Report.UPLOAD_REPORT:
    case Report.UPLOAD_PDF:
      return { ...state, loading: true };
    case Report.UPLOAD_REPORT_SUCCEEDED:
      return {
        ...state,
        //  loading: false
      };
    case Report.UPLOAD_PDF_SUCCEEDED:
      return {
        ...state,
        pdfLink: action.payload,
        loading: false,
      };
    case Report.UPLOAD_PICTURES_PROGRESS:
      return {
        ...state,
        uploadProgress: action.payload,
        loadingPicture: true,
      };
    case Report.UPLOAD_PICTURE:
      return {
        ...state,
        loadingPicture: true,
      };
    case Report.UPLOAD_PICTURES_FINISHED:
      return { ...state, uploadProgress: 0, loadingPicture: false, needSave: false };
    case Report.UPLOAD_PICTURE_SUCCEEDED:
    case Report.UPLOAD_PICTURE_FAILED:
      return { ...state, loadingPicture: false };
    case Report.UPLOAD_REPORT_FAILED:
      return { ...state, loading: false, error: action.payload };
    case Report.ADD_PICTURE:
      return {
        ...state,
        loadingPicture: false,
        pictures: [...state.pictures, action.payload],
        needSave: true,
      };
    case Report.REMOVE_PICTURE:
      if (state.pictures.some((x) => x.id === action.payload)) {
        return {
          ...state,
          pictures: [...state.pictures.filter((x) => x.id !== action.payload)],
        };
      } else {
        return {
          ...state,
          parameters: state.parameters.map((x) => {
            if (x.descriptions.some((d) => d.pictures?.some((p) => p.id === action.payload))) {
              return {
                ...x,
                descriptions: x.descriptions.map((xd) => {
                  return { ...xd, pictures: xd.pictures?.filter((pic) => pic.id !== action.payload) };
                }),
              };
            }
            return x;
          }),
        };
      }

    case Report.UPDATE_REPORT_HOUSE_PICTURE:
      return {
        ...state,
        loading: false,
        needSave: true,
        data: {
          ...state.data,
          picture: action.payload,
        },
      };
    case Report.NEED_UPDATE:
      return { ...state, init: true };
    case Report.ADD_NEW_PARAMETER_ITEM:
      return {
        ...state,
        needSave: true,
        parameters: [...state.parameters, action.payload],
        newCustomParamIds: [...(state.newCustomParamIds?.length ? state.newCustomParamIds : []), action.payload.id],
      };
    case Report.REMOVE_PARAMETER_ITEM: {
      const paramToRemove = state.parameters.find((parameter) => parameter.id === action.payload);
      if (!paramToRemove) return state;
      const template = state.objects.find((x) => x.id === paramToRemove.objectId)?.template || state.template;
      if (!template) return;
      const groupItemId = template.sections?.flatMap((x) => x.items).find((x) => x.id === paramToRemove.itemId)?.id;
      const toRemove = [
        paramToRemove,
        ...state.parameters.filter((x) => x.objectId === paramToRemove.objectId && x.groupItemId === groupItemId),
      ];
      return {
        ...state,
        needSave: true,
        parameters: state.parameters.filter((x) => !toRemove.some((r) => r.id === x.id)),
        objects: state.objects.map((object) => {
          if (object.id !== paramToRemove.objectId) return object;
          return {
            ...object,
            template: {
              ...object.template,
              sections: object.template?.sections.map((section) => {
                return {
                  ...section,
                  items: section.items.filter((item) => !toRemove.some((r) => r.itemId === item.id)),
                };
              }),
            },
          };
        }),
      };
    }
    case Report.ADD_NEW_TEMPLATE_ITEM:
      return {
        ...state,
        needSave: true,
        objects: state.objects?.map((obj) => {
          if (obj.id === action.payload.objectId) {
            return {
              ...obj,
              template: {
                ...obj.template,
                sections: obj.template?.sections?.map((x) => {
                  if (x.id === action.payload.item.parentId) {
                    return { ...x, items: [...x.items, action.payload.item] };
                  }
                  return x;
                }),
              },
            };
          }
          return obj;
        }),
      };
    case Report.UPDATE_TEMPLATE_ITEM:
      return {
        ...state,
        needSave: true,
        objects: state.objects?.map((obj) => {
          if (obj.id === action.payload.objectId) {
            return {
              ...obj,
              template: {
                ...obj.template,
                sections: obj.template?.sections?.map((x) => {
                  if (x.id === action.payload.item.parentId) {
                    return {
                      ...x,
                      items: x.items.map((i) => {
                        if (i.id === action.payload.item.id) {
                          return { ...action.payload.item };
                        }
                        return i;
                      }),
                    };
                  }
                  return x;
                }),
              },
            };
          }
          return obj;
        }),
      };
    case Report.CHANGE_SECTION_HIDDEN_STATE:
      return {
        ...state,
        needSave: true,
        objects: state.objects?.map((obj) => {
          if (obj.id === action.payload.objectId) {
            // //clean up evaluations when hiding a section for the correct calculation
            // const evals = {...obj.sectionEvaluations}
            // const removeEvaluation =  obj.template?.sections?.some(x => x.id === action.payload.sectionId && !x.hidden);
            // if(removeEvaluation)
            // {
            //   if(Object.hasOwn(evals, action.payload.sectionId)){
            //     delete evals[action.payload.sectionId];
            //   }
            // }
            return {
              ...obj,
              //sectionEvaluations: evals,
              template: {
                ...obj.template,
                sections: obj.template?.sections?.map((x) => {
                  if (x.id === action.payload.sectionId) {
                    return { ...x, hidden: !x.hidden };
                  }
                  return x;
                }),
              },
            };
          }
          return obj;
        }),
      };

    case Report.ADD_TEMPLATE_TO_OBJECT:
      return {
        ...state,
        objects: state.objects?.map((obj) => {
          if (obj.id === action.payload.objectId) {
            return {
              ...obj,
              template: {
                ...state.template,
              },
            };
          }
          return obj;
        }),
      };

    case Report.UPDATE_OBJECT_TEMPLATE:
      return {
        ...state,
        objects: state.objects?.map((obj) => {
          if (obj.id === action.payload.objectId) {
            return {
              ...obj,
              template: action.payload.template,
            };
          }
          return obj;
        }),
      };

    case Report.ADD_DESCRIPTION:
      return {
        ...state,
        needSave: true,
        parameters: state.parameters.map((p) => {
          if (action.payload.itemId === p.id) {
            return { ...p, descriptions: [...p.descriptions, action.payload.desc] };
          }
          return p;
        }),
      };
    case Report.REMOVE_DESCRIPTION:
      return {
        ...state,
        needSave: true,
        parameters: state.parameters.map((p) => {
          if (p.id === action.payload.itemId) {
            return {
              ...p,
              descriptions: p.descriptions.filter((x) => x.id !== action.payload.descriptionId),
            };
          }
          return p;
        }),
      };
    case Report.CHANGE_DESCRIPTION:
      return {
        ...state,
        needSave: true,
        parameters: state.parameters.map((p) => {
          if (p.id === action.payload.itemId) {
            return {
              ...p,
              descriptions: p.descriptions.map((d) => {
                if (d.id === action.payload.descriptionId) {
                  return { ...d, description: action.payload.value };
                }
                return d;
              }),
            };
          }
          return p;
        }),
      };
    case Report.ADD_DESCRIPTION_PHOTO:
      return {
        ...state,
        //loadingPicture: false,
        needSave: true,
        parameters: state.parameters.map((p) => {
          if (p.id === action.payload.itemId) {
            return {
              ...p,
              descriptions: p.descriptions.map((d) => {
                if (d.id === action.payload.descriptionId) {
                  return { ...d, pictures: d.pictures ? [...d.pictures, action.payload.pic] : [action.payload.pic] };
                }
                return d;
              }),
            };
          }
          return p;
        }),
      };
    //this action is replaced by REMOVE_PICTURE because of
    //the old implementation of ImageModal component
    case Report.REMOVE_DESCRIPTION_PHOTO:
      return {
        ...state,
        needSave: true,
        parameters: state.parameters.map((p) => {
          if (p.id === action.payload.itemId) {
            return {
              ...p,
              descriptions: p.descriptions.map((d) => {
                if (d.id === action.payload.descriptionId) {
                  return { ...d, pictures: d.pictures?.filter((p) => p.id !== action.payload.picId) || [] };
                }
                return d;
              }),
            };
          }
          return p;
        }),
      };
    case Report.HIDE_GROUP_VALUES:
      return {
        ...state,
        needSave: true,
        parameters: state.parameters.map((x) => {
          const val = action.payload.find((v: string) => x.id === v);
          if (val) {
            return { ...x, hidden: true };
          }
          return x;
        }),
      };

    case Report.ADD_TO_WISHLIST:
      if (state.wishList.find((x) => x.id === action.payload.id)) {
        return {
          ...state,
          needSave: true,
          wishList: state.wishList.map((x) => {
            if (x.id === action.payload.id) {
              return { ...x, ...action.payload };
            }
            return x;
          }),
        };
      }
      return { ...state, needSave: true, wishList: [...state.wishList, action.payload] };
    case Report.REMOVE_FROM_WISHLIST:
      return { ...state, needSave: true, wishList: state.wishList.filter((x) => x.id !== action.payload) };
    case Report.UPDATE_REPORT_MAINTENANCE_INFO:
      return { ...state, maintenanceInfo: action.payload };
    case Report.AUTOSAVE: {
      return { ...state, autoSave: !state.autoSave };
    }
    case Report.EVALUATE_SECTION: {
      return {
        ...state,
        needSave: true,
        objects: state.objects?.map((obj) => {
          if (obj.id === action.payload.objectId) {
            return {
              ...obj,
              sectionEvaluations: {
                ...obj.sectionEvaluations,
                [action.payload.sectionId]: action.payload.value,
              },
            };
          }
          return obj;
        }),
      };
    }
    case Report.CALCULATE_EVALUATION: {
      return { ...state, evaluation: action.payload };
    }
    case Report.COLLAPSE_ALL: {
      return { ...state, collapseAll: action.payload };
    }

    // case Report.OUT_OF_SYNC:
    //   return { ...state, sync: false };
    // case Report.SYNCHRONIZED:
    //   return { ...state, sync: true };

    //sync customer wishlist with inspector wishlist
    case Report.UPDATE_WISHLIST_FROM_CUSTOMER: {
      return {
        ...state,
        needSave: true,
        wishList: action.payload.map((x: WishListParameter) => {
          const existing = state.wishList.find((w) => w.id === x.id);
          if (existing) {
            return { ...existing, label: x.label };
          }
          return x;
        }),
      };
    }
    case Report.INIT_REPORT:
      return {
        ...action.payload,
      };
    case Common.GOT_ERROR:
      return { ...state, init: false, loading: false };
    case User.LOGOUT:
      return { ...initialState };
    case Report.NEED_SAVE_REPORT:
      return {
        ...state,
        needSave: action.payload,
      };
    case Report.MERGED_REPORT:
      return {
        ...state,
        isMerged: action.payload,
      };
    case Report.SET_ACTIVE_REPORT_LAYOUT:
      return {
        ...state,
        isActive: action.payload,
      };
      case Report.CHANGE_INFO_ITEM: {
        let needSave = false;
        const newInfoItems = state.infoItems.map((x) => {
          if (x.id === action.payload.itemId) {
            const updatedInfoItems: InfoItem = { ...x };
            updatedInfoItems.description = action.payload.value;
            needSave = true;
            return updatedInfoItems;
          }
          return x;
        });
        return {
          ...state,
          infoItems: newInfoItems,
          needSave: needSave,          
        };
      }
    default:
      return state;
  }
};

export default reportReducer;
